import React from "react"
import { Link } from "gatsby"

import styles from "./styles.module.css"

const ProjectCard = ({ icon, slug, title, description, active, ...props }) => (
  <Link
    to={slug}
    className={`${styles.card} ${active ? styles.active : ""}`}
    style={{ ...props.style, ...props.cardStyle }}
  >
    <img src={icon.src} className={styles.icon} alt={`${title} Icon`} />
    <h1 className={styles.title}>{title}</h1>
    <p className={styles.description}>{description}</p>
  </Link>
)

export default ProjectCard
