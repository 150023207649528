import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Portfolio from "../components/Pages/Portfolio"

import "../styles/styles.css"

const query = graphql`
  query {
    headerImg: imageSharp(
      original: { src: { regex: "/.*dotted-kangaroo.*/" } }
    ) {
      id
      fixed(height: 700, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/pages/portfolio/" } }) {
      frontmatter {
        title
        subtitle
      }
    }
    projects: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            category
            icon {
              childImageSharp {
                fluid(maxHeight: 50, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      return (
        <Portfolio
          {...props}
          page={data.page.frontmatter}
          headerImg={data.headerImg}
          projects={data.projects.edges}
        />
      )
    }}
  />
)

export default PortfolioPage
